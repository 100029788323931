import React from "react"
import ReactMarkdown from "react-markdown"
import Icon from "../../Icon"

import * as styles from "./CallOutItem.module.scss"
import { ICallOutItem } from "./types"

const CallOutItem = ({ item }: ICallOutItem) => {
  return (
    <div className={styles.callOutItem}>
      <Icon name="purpleSquares" />
      <div className={styles.callOutItemContent}>
        <h3>{item?.title}</h3>
        <ReactMarkdown className="lineBreak">
          {item?.description?.description as string}
        </ReactMarkdown>
      </div>
    </div>
  )
}

export default CallOutItem
