import React from "react"
import CallOutItem from "../CallOutItem/CallOutItem"
import Container from "../Container/Container"

import * as styles from "./CallOutList.module.scss"
import { ICallOutList } from "./types"

const mock = [
  {
    title: "Powered by Digital Art Fair",
    description:
      "DAFAx is the official NFT Marketplace powered by Digital Art Fair. Created by a team that is passionate about art and technology, with a goal to provide a well-curated, digital fine art platform.DAFAx is the official NFT Marketplace powered by Digital Art Fair. Created by a team that is passionate about art and technology, with a goal to provide a well-curated, digital fine art platform.",
  },
  {
    title: "Discover groundbreaking artworks",
    description:
      "We make it simple to discover incredible digital art pieces from all over the world. Our aim is to find true talent and provide a platform for digital artists to reach a global network of art collectors.",
  },
  {
    title: "Curated by the community",
    description:
      "Each month we will invite respected figures in the digital art community to curate our NFT art drops. We believe in the vision of others and we encourage a range of creational tastes.",
  },
]

const CallOutList = ({ callOutList }: ICallOutList) => {
  return (
    <Container>
      <div className={styles.callOutList}>
        {callOutList?.map((callOut, i) => (
          <CallOutItem key={i} item={callOut} />
        ))}
      </div>
    </Container>
  )
}

export default CallOutList
