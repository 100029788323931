import React from "react"
import cn from "classnames"
import * as styles from "./AnimatedPlayIcon.module.scss"

interface AnimatedPlayIconProps {
  isPlaying: boolean
  className?: string
}

export const AnimatedPlayIcon = ({
  isPlaying,
  className,
}: AnimatedPlayIconProps) => {
  return (
    <span
      className={cn(
        styles.animatedPlayIconlaybuttoncontainer,
        {
          [styles.videoIsPlaying]: isPlaying,
        },
        className
      )}
    >
      <span
        className={cn(
          styles.animatedPlayIconplaybutton,
          styles.animatedPlayIconplaybuttonbefore
        )}
      ></span>
      <span
        className={cn(
          styles.animatedPlayIconplaybutton,
          styles.animatedPlayIconplaybuttonafter
        )}
      ></span>
    </span>
  )
}
