import React, { MouseEvent, useEffect, useRef, useState } from "react"

import * as styles from "./Hero.module.scss"
import Container from "../Container/Container"
import Icon from "../../Icon"
import { IHero } from "./types"
import ReactMarkdown from "react-markdown"
import cn from "classnames"
import { AnimatedPlayIcon } from "../AnimatedPlayIcon/AnimatedPlayIcon"
import { VideoContainer } from "../VideoContainer/VideoContainer"

const Hero = ({ heroData }: IHero) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const [isTimeOut, setTimeOut] = useState(false)
  const [isMute, setIsMute] = useState(true)
  const videoRef: React.LegacyRef<HTMLVideoElement> | undefined = useRef(null)

  const playVideo = () => {
    setIsPlaying(true)
    videoRef.current?.play()
  }

  const pauseVideo = () => {
    setIsPlaying(false)
    videoRef.current?.pause()
  }

  const muteVideo = () => {
    setIsMute(!isMute)
  }

  let timer: NodeJS.Timeout
  const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    setTimeOut(false)
    clearTimeout(timer)
    timer = setTimeout(() => {
      setTimeOut(true)
    }, 5000)
  }

  useEffect(() => {
    const onPlaying = () => {
      setIsPlaying(true)
    }

    const onPause = () => {
      setIsPlaying(false)
    }

    if (videoRef.current) {
      videoRef.current.addEventListener("playing", onPlaying)
      videoRef.current.addEventListener("pause", onPause)
    }
  }, [videoRef])

  return (
    <Container>
      <div className={styles.hero}>
        <VideoContainer
          src={heroData.contentfulHomepage?.video?.file?.url!}
          title={heroData.contentfulHomepage?.video?.title!}
        />

        <div className={styles.heroInner}>
          <h1>{heroData.contentfulHomepage?.title}</h1>

          <ReactMarkdown className="lineBreak">
            {heroData.contentfulHomepage?.description?.description as string}
          </ReactMarkdown>
        </div>
      </div>
    </Container>
  )
}

export default Hero
