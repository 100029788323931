// extracted by mini-css-extract-plugin
export var embla = "InfiniteCarousel-module--embla--4c1f9";
export var emblaScrollNextButton = "InfiniteCarousel-module--emblaScrollNextButton--e072a";
export var emblaScrollPrevButton = "InfiniteCarousel-module--emblaScrollPrevButton--382e7";
export var embla__button = "InfiniteCarousel-module--embla__button--497e2";
export var embla__buttonNext = "InfiniteCarousel-module--embla__button--next--0bd13";
export var embla__buttonPrev = "InfiniteCarousel-module--embla__button--prev--0acdd";
export var embla__button__svg = "InfiniteCarousel-module--embla__button__svg--93d11";
export var embla__container = "InfiniteCarousel-module--embla__container--7fcb8";
export var embla__slide = "InfiniteCarousel-module--embla__slide--3f64f";
export var embla__slide__img = "InfiniteCarousel-module--embla__slide__img--6f8be";
export var embla__slide__inner = "InfiniteCarousel-module--embla__slide__inner--f6261";
export var embla__slide__inner_loading = "InfiniteCarousel-module--embla__slide__inner_loading--dccc9";
export var embla__slide__loading = "InfiniteCarousel-module--embla__slide__loading--c3d25";
export var embla__slide_content = "InfiniteCarousel-module--embla__slide_content--e0440";
export var embla__slide_loading = "InfiniteCarousel-module--embla__slide_loading--5cba3";
export var embla__viewport = "InfiniteCarousel-module--embla__viewport--d334c";
export var isDraggable = "InfiniteCarousel-module--is-draggable--dd1ff";
export var isDragging = "InfiniteCarousel-module--is-dragging--d0393";
export var loading = "InfiniteCarousel-module--loading--27073";