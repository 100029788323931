import React from "react"
import Container from "../Container/Container"

import * as styles from "./InfiniteCarouselHeading.module.scss"
import { IInfiniteCarouselHeading } from "./types"

const InfiniteCarouselHeading = ({
  carouselHeading,
}: IInfiniteCarouselHeading) => {
  return (
    <div className={styles.infiniteCarouselHeading}>
      <Container>
        <h2>{carouselHeading}</h2>
      </Container>
    </div>
  )
}

export default InfiniteCarouselHeading
