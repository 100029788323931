import React, { MouseEvent, useEffect, useRef, useState } from "react"
import * as styles from "./VideoContainer.module.scss"
import Icon from "../../Icon"
import { AnimatedPlayIcon } from "../AnimatedPlayIcon/AnimatedPlayIcon"
import cn from "classnames"

interface VideoContainerProps {
  src: string
  title: string
}

export const VideoContainer = ({ src, title }: VideoContainerProps) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const [isTimeOut, setTimeOut] = useState(false)
  const [isMute, setIsMute] = useState(true)
  const videoRef: React.LegacyRef<HTMLVideoElement> | undefined = useRef(null)

  const playVideo = () => {
    setIsPlaying(true)
    videoRef.current?.play()
  }

  const pauseVideo = () => {
    setIsPlaying(false)
    videoRef.current?.pause()
  }

  const muteVideo = () => {
    setIsMute(!isMute)
  }

  let timer: NodeJS.Timeout
  const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    setTimeOut(false)
    clearTimeout(timer)
    timer = setTimeout(() => {
      setTimeOut(true)
    }, 5000)
  }

  useEffect(() => {
    const onPlaying = () => {
      setIsPlaying(true)
    }

    const onPause = () => {
      setIsPlaying(false)
    }

    if (videoRef.current) {
      videoRef.current.addEventListener("playing", onPlaying)
      videoRef.current.addEventListener("pause", onPause)
    }
  }, [videoRef])

  return (
    <div onMouseMove={onMouseMove} className={styles.VideoContainer}>
      <video
        src={src}
        title={title}
        muted={isMute}
        autoPlay
        loop
        playsInline
        ref={videoRef}
      />
      <div className={isPlaying ? styles.isPlaying : ""}>
        <button
          className={cn(styles.videoContainerPlayButton, {
            [styles.videoContainerPlayButtonTimeOut]: isTimeOut,
          })}
          onClick={isPlaying ? pauseVideo : playVideo}
        >
          <div
            className={cn({
              [styles.videoContainerIsTimeout]: isTimeOut,
            })}
          >
            <AnimatedPlayIcon isPlaying={isPlaying} />
          </div>
        </button>
        <button
          className={cn(styles.videoContainerMuteButton, {
            [styles.videoContainerIsTimeout]: isTimeOut,
          })}
          onClick={muteVideo}
        >
          {isMute ? <Icon name="unmute" /> : <Icon name="mute" />}
        </button>
      </div>
    </div>
  )
}
