import React, { useEffect, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import Hero from "../components/Hero/Hero"
import Seo from "../components/Seo/seo"
import Cta from "../components/Cta/Cta"
import InfiniteCarousel from "../components/InfiniteCarousel/InfiniteCarousel"
import InfiniteCarouselHeading from "../components/InfiniteCarouselHeading/InfiniteCarouselHeading"
import CallOutList from "../components/CallOutList/CallOutList"

import CallToActionSquare from "../components/CallToActionSquare/CallToActionSquare"
import { ContentfulHomePageQuery } from "../../types/gatsby-graphql"

const IndexPage = () => {
  const data: ContentfulHomePageQuery = useStaticQuery(graphql`
    query ContentfulHomePage {
      contentfulHomepage {
        video {
          file {
            url
          }
          title
        }
        title
        description {
          description
        }
        callToActionText
        carouselHeading
        secondCarouselHeading
        featureSales {
          __typename
          ... on ContentfulFeaturedSale {
            title
            description {
              description
            }
            price
            image {
              file {
                url
              }
              title
            }
            url {
              url
            }
          }
          ... on ContentfulFeaturedSaleCollection {
            title
            description {
              description
            }
            price
            image {
              file {
                url
              }
              title
            }
            url {
              url
            }
          }
        }
        callOutList {
          title
          description {
            description
          }
        }
      }
    }
  `)

  const firstData = data.contentfulHomepage?.featureSales?.filter(
    item => item?.__typename === "ContentfulFeaturedSale"
  )
  const secondData = data.contentfulHomepage?.featureSales?.filter(
    item => item?.__typename !== "ContentfulFeaturedSale"
  )

  const [slides, setSlides] =
    useState<
      NonNullable<ContentfulHomePageQuery["contentfulHomepage"]>["featureSales"]
    >(firstData)
  const [secondSlides, setSecondSlides] =
    useState<
      NonNullable<ContentfulHomePageQuery["contentfulHomepage"]>["featureSales"]
    >(secondData)

  useEffect(() => {
    if (data) {
      setSlides(firstData)
      setSecondSlides(secondData)
    }
  }, [data])

  return (
    <div>
      <Seo title="Home" />
      <Hero heroData={data} />
      <Cta cta={data.contentfulHomepage?.callToActionText} />
      <CallToActionSquare />
      {secondSlides && secondSlides?.length > 0 && (
        <>
          <InfiniteCarouselHeading
            carouselHeading={data.contentfulHomepage?.secondCarouselHeading}
          />
          <InfiniteCarousel slides={secondSlides} setSlides={setSecondSlides} />
        </>
      )}
      <InfiniteCarouselHeading
        carouselHeading={data.contentfulHomepage?.carouselHeading}
      />
      <InfiniteCarousel slides={slides} setSlides={setSlides} sold />
      <CallOutList callOutList={data.contentfulHomepage?.callOutList} />
    </div>
  )
}

export default IndexPage
