import React from "react"

import Container from "../Container/Container"
import classNames from "classnames"
import { Link } from "gatsby"

import signUpSquare from "../../images/SignUpSquare.png"
import openCallSquare from "../../images/OpenCallSquare.png"
import whitelistSquare from "../../images/WhitelistSquare.png"
import acSquare from "../../images/a&cSquare2.png"

import * as styles from "./CallToActionSquare.module.scss"

const CallToActionSquare = () => {
  return (
    <Container>
      <div className={styles.callToActionSquare}>
        <Link
          to="/sign-up"
          className={classNames(
            styles.callToActionSquareLink,
            styles.callToActionSquareLinkTop,
            styles.first
          )}
        >
          <img src={signUpSquare} alt="Sign Up" />
        </Link>

        <Link
          to="/whitelist"
          className={classNames(
            styles.callToActionSquareLink,
            styles.callToActionSquareLinkBottom,
            styles.second
          )}
        >
          <img src={whitelistSquare} alt="Whitelist Invite" />
        </Link>

        <Link
          to="/artist-collector-rights"
          className={classNames(
            styles.callToActionSquareLink,
            styles.callToActionSquareLinkBottom,
            styles.third
          )}
        >
          <img src={acSquare} alt="Artist & Collectors Rights" />
        </Link>
      </div>
    </Container>
  )
}

export default CallToActionSquare
