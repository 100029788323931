import React, { useState, useEffect, useCallback } from "react"
import useEmblaCarousel from "embla-carousel-react"

import classNames from "classnames"
import { IInfiniteCarousel } from "./types"

import * as styles from "./InfiniteCarousel.module.scss"
import Icon from "../../Icon"

const mockApiCall = (
  minWait: number,
  maxWait: number,
  callback: () => void
) => {
  const min = Math.ceil(minWait)
  const max = Math.floor(maxWait)
  const wait = Math.floor(Math.random() * (max - min + 1)) + min
  setTimeout(callback, wait)
}

const InfiniteCarousel = ({
  slides,
  setSlides,
  sold = false,
}: IInfiniteCarousel) => {
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true)
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    inViewThreshold: 0,
    containScroll: "trimSnaps",
  })
  const [loadingMore, setLoadingMore] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const onSelect = useCallback(() => {
    if (!embla) return
  }, [embla])

  useEffect(() => {
    if (!loadingMore) return
    mockApiCall(1000, 2000, () => {
      setSlides(currentSlides => {
        if (currentSlides?.length === 20) {
          setHasMoreToLoad(false)
          return currentSlides
        }

        let newSlides: IInfiniteCarousel["slides"] = currentSlides
        newSlides?.push(...(currentSlides as []))

        setLoadingMore(false)
        return newSlides
      })
    })
  }, [setSlides, loadingMore])

  useEffect(() => {
    if (!embla) return
    embla.on("select", onSelect)
    embla.on("reInit", onSelect)
    onSelect()
  }, [embla, onSelect])

  const scrollPrev = useCallback(() => {
    if (embla) {
      if (scrollPosition > 0) {
        const prev = scrollPosition - 3
        embla.scrollTo(prev)
        setScrollPosition(prev)
      }
    }
  }, [embla, scrollPosition])

  const scrollNext = useCallback(() => {
    if (embla) {
      if (scrollPosition + 3 < slides?.length!) {
        const next = scrollPosition + 3
        embla.scrollTo(next)
        setScrollPosition(next)
      }
    }
  }, [embla, scrollPosition])

  return (
    <>
      {slides ? (
        <div className={styles.embla}>
          <div className={styles.embla__viewport} ref={viewportRef}>
            <div className={styles.embla__container}>
              {slides.map((slide, i) => (
                <a key={i} href={slide?.url?.url ?? "#"}>
                  <div className={styles.embla__slide}>
                    <div className={styles.embla__slide__inner}>
                      <img
                        className={styles.embla__slide__img}
                        src={slide?.image?.file?.url as string}
                        alt={slide?.image?.title as string}
                      />
                    </div>
                    <div className={styles.embla__slide_content}>
                      <h3>{slide?.title}</h3>
                      <span>{slide?.description?.description}</span>
                      <span>
                        {sold ? "Sold For" : "Price"}: {slide?.price} ETH
                      </span>
                    </div>
                  </div>
                </a>
              ))}
              {hasMoreToLoad && (
                <div
                  className={classNames(
                    styles.embla__slide,
                    styles.embla__slide_loading
                  )}
                >
                  <div
                    className={classNames(
                      styles.embla__slide__inner,
                      styles.embla__slide__inner_loading
                    )}
                  >
                    {loadingMore && (
                      <div className={styles.embla__slide__loading} />
                    )}
                  </div>
                </div>
              )}
            </div>
            {scrollPosition > 0 && (
              <button
                className={styles.emblaScrollPrevButton}
                onClick={scrollPrev}
              >
                <Icon name="preview" width="50" height="50" />
              </button>
            )}
            <button
              className={styles.emblaScrollNextButton}
              onClick={scrollNext}
            >
              <Icon name="next" width="50" height="50" />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default InfiniteCarousel
