import React from "react"
import Container from "../Container/Container"

import * as styles from "./Cta.module.scss"
import { ICta } from "./types"

const Cta = ({ cta }: ICta) => {
  return (
    <div className={styles.cta}>
      <div className={styles.ctaHeading}>
        <Container>
          <h2>{cta}</h2>
        </Container>
      </div>
    </div>
  )
}

export default Cta
